import React from "react"

const PaymentDetailsSummary = ({ paymentOption, changeFor }) => {
  if (paymentOption === "Cash on Delivery")
    return (
      <div className="px-1">
        <p className="is-size-5">{paymentOption}</p>
        <p className="is-size-6">Change for: {changeFor}</p>
      </div>
    )

  return (
    <div className="px-1">
      <p className="is-size-5">{paymentOption}</p>
      <p className="is-size-6">
        After placing your order, our pharmacist will confirm your order and
        send our bank details.
      </p>
    </div>
  )
}

export default PaymentDetailsSummary
