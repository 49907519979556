import { firestore } from "./firebase/firebaseApi"

export const handleGetUserByEmail = async ({ email }) => {
  let results = await firestore.post("/get", {
    collection: "users",
    column: "email",
    searchKey: email,
  })
  results = results.data

  if (results.length > 0) return { id: results[0].id, ...results[0].data }
  return null
}
