import React from "react"

import {
  DeliveryFeeRow,
  CouponDiscountRow,
} from "./MedsOrderedSummary/MedicineInfoRow"
import {
  generateDeliveryFee,
  accumulatePrice,
  accumulateVat,
} from "../Epharmacy/services/computations"
import { getCouponDiscountAmount } from "../Epharmacy/services/coupon"
import specialCoupons from "../Epharmacy/utils/specialCoupons.json"
import OrderTotal from "./MedsOrderedSummary/OrderTotal"

const OrderSubtotalSummary = ({ province, medicines, coupon, addressType }) => {
  const discount = getCouponDiscountAmount(
    coupon,
    medicines,
    generateDeliveryFee({ couponCode: coupon?.code, province: province })
  )

  const accumulatedVAT = specialCoupons.includes(coupon?.code)
    ? parseFloat(accumulateVat(medicines))
    : 0

  const discountWithVat = (parseFloat(discount) + accumulatedVAT).toFixed(2)

  return (
    <div className="px-1 mb-3">
      <DeliveryFeeRow
        deliveryFee={generateDeliveryFee({
          coupon: coupon,
          couponCode: coupon?.code,
          province: province,
          addressType: addressType,
        }).toFixed(2)}
      />
      {coupon && (
        <CouponDiscountRow
          couponCode={coupon?.code}
          couponDiscount={discountWithVat}
          coupon={coupon}
        />
      )}
      <OrderTotal
        totalPrice={(
          accumulatePrice(medicines) +
          generateDeliveryFee({
            coupon: coupon,
            couponCode: coupon?.code,
            province: province,
            addressType: addressType,
          }) -
          discountWithVat
        ).toFixed(2)}
      />
    </div>
  )
}

export default OrderSubtotalSummary
