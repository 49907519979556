export const handleGetDefaultDocuments = ({ type, document }) => {
  switch (type) {
    case "user":
      return {
        lastName: document.lastName,
        email: document.emailAddress,
        firstName: document.firstName,
        middleName: document.middleName,
        viberNumber: document.viberNumber,
        mobileNumber: document.mobileNumber,
      }
    case "order":
      return {
        nps: document.nps,
        birthday: document.birthday,
        lastName: document.lastName,
        email: document.emailAddress,
        zendeskId: document.zendeskId,
        changeFor: document.changeFor,
        firstName: document.firstName,
        middleName: document.middleName,
        orderNotes: document.orderNotes,
        viberNumber: document.viberNumber,
        mobileNumber: document.mobileNumber,
        selectedBank: document.selectedBank,
        coupon: document?.coupon?.code || "",
        paymentOption: document.paymentOption,
        scpwdIDNumber: document.scpwdIDNumber,
        deliveryAddress: document.deliveryAddress,
        hasPartnerRequest: document.hasPartnerRequest,
        ifMedicineNotAvailable: document.ifMedicineNotAvailable,
      }

    case "medicine":
      return {
        ...document,
        quantity: document?.qty,
        priceList: {
          name: document?.price_list?.name,
          vat: document?.price_list?.vat,
          vatexUnitPrice: document?.price_list?.vatex_unit_price,
        },
      }
  }
}
