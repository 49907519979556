import FormRadio from "../../Elements/Form/FormRadio"
import teleconsultSurveyDynamicFields from "./teleconsultSurveyDynamicFields.json"

import {
  consultingDoctorOnline,
  numberOfConsultations,
  openForConsulting,
  howMuchWillingToPay,
} from "./teleconsultOptions"

export default [
  {
    title: "Are you interested in consulting with your doctor online?",
    name: "teleconsultSurvey.consultingDoctorOnline",
    valueName: "consultingDoctorOnline",
    component: FormRadio,
    options: consultingDoctorOnline,
    isRequired: false,
    zendeskKey: "Are you interested in consulting with your doctor online?",
    hideOptional: true,
  },
  {
    title: "How often do you consult with your doctor in a year?",
    name: "teleconsultSurvey.numberOfConsultations",
    valueName: "numberOfConsultations",
    component: FormRadio,
    options: numberOfConsultations,
    isRequired: false,
    hideOptional: true,
    followUpQuestions: ["teleconsultSurvey.howOftenYouConsult"],
    followUpQuestionValueName: "howOftenYouConsult",
    formFields: teleconsultSurveyDynamicFields,
    isFollowUpQuestion: true,
    zendeskKey: "How often do you consult with your doctor in a year?",
  },
  {
    title:
      "Are you open to consulting with another doctor if your doctor is unavailable?",
    name: "teleconsultSurvey.openForConsulting",
    valueName: "openForConsulting",
    component: FormRadio,
    options: openForConsulting,
    isRequired: false,
    className: "mt-2",
    zendeskKey:
      "Are you open to consulting with another doctor if your doctor is unavailable?",
    hideOptional: true,
  },
  {
    title: "How much are you willing to pay for an online consultation?",
    name: "teleconsultSurvey.howMuchWillingToPay",
    valueName: "howMuchWillingToPay",
    component: FormRadio,
    options: howMuchWillingToPay,
    isRequired: false,
    hideOptional: true,
    zendeskKey: "How much are you willing to pay for an online consultation?",
  },
]
