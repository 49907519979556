import React, { Fragment, useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import { get } from "lodash"

import Layout from "../Layout/Layout"
import ActionButtons from "../Elements/ActionButtons"
import Section from "../Elements/Section"
import Container from "layout/Container"
import Message from "elements/Message"
import EditDetailsButton from "../Summary/EditDetailsButton"

import MedsOrderedSummary from "../Summary/MedsOrderedSummary"
import UploadDocumentsSummary from "../Summary/UploadedDocumentsSummary"
import ConsentAndAuthorization from "../Summary/ConsentAndAuthorization"
import OrderSubtotalSummary from "../Summary/OrderSubtotalSummary"
import PaymentDetailsSummary from "../Summary/PaymentDetailsSummary"

import { sendRequest } from "../../services/order"
import { shouldBeDisabled } from "./services/summary"
import { orderTicketBody } from "./utils/templates/epharmacyOrderZendeskTemplate"
import { orderConfirmation } from "./utils/templates/epharmacyOrderEmailTemplate"

import { AppContext } from "../../context/AppContext"
import { hasIncompleteValues } from "../Elements/Form/services/form"
import { parseFormField } from "../../services/airtable"
import { getContextFromSession } from "../../context/services/context"
import styles from "../Summary/utils/summary.module.scss"

const Summary = (props) => {
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  let { pageContext } = props
  const { epharmacy, documents, scpwdId } = state

  const {
    deliveryAddress,
    medicines,
    coupon,
    paymentOption,
    changeFor,
  } = epharmacy

  let summaryFields = pageContext.summaryFields

  let selectedCoupon = state?.epharmacy?.coupon
  if (!selectedCoupon?.code?.startsWith?.("MGBESTLIFE")) {
    summaryFields = summaryFields?.filter(
      (formField) => !formField?.inclusions?.includes("Best Life")
    )
  }

  let uploadedDocuments = [...documents]
  if (scpwdId.front.path) uploadedDocuments.push(scpwdId.front)
  if (scpwdId.back.path) uploadedDocuments.push(scpwdId.back)

  let sectionSummaryFields = parseFormField(
    summaryFields.map((formField) => ({
      ...formField,
      section: formField.summarySection,
    }))
  )
  
  sectionSummaryFields.push(
    {
      section: "Medicines Ordered",
      order: 2,
      isUniqueSection: true,
    },
    {
      section: "Documents Uploaded",
      order: 4,
      isUniqueSection: true,
    },
    {
      section: "Payment Method",
      order: 5,
      isUniqueSection: true,
    },
    {
      section: "Consent and Authorization",
      order: 7,
      isUniqueSection: true,
    }
  )

  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleOnSubmit = async () => {
    setLoading(true)
    await sendRequest(
      "epharmacy",
      state,
      uploadedDocuments,
      orderConfirmation,
      orderTicketBody,
      async () => {
        await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
        await dispatch({ type: "RESET_DETAILS" })
        navigate("/epharmacy/completed")
      },
      () => {
        dispatch({
          type: "SHOW_TOAST",
          payload: {
            message:
              "There was a problem in sending your order. Please try again.",
            color: "danger",
          },
        })
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  useEffect(() => {
    let currentContext = getContextFromSession()

    const hasMedicines = currentContext?.epharmacy?.medicines.length > 0
    const isPartnerRequest = !!currentContext?.epharmacy?.hasPartnerRequest
    const isMedGrocerWellnessPatient = !!currentContext?.epharmacy
      ?.isMedGrocerWellnessPatient
    const hasRxRequiredMedicine = currentContext?.epharmacy?.medicines.some(
      (medicine) => !!medicine.rxRequired
    )
    if (!hasMedicines && !isPartnerRequest && !isMedGrocerWellnessPatient) {
      navigate("/epharmacy")
    }

    if (hasRxRequiredMedicine && currentContext?.documents?.length === 0) {
      navigate("/epharmacy/upload")
    }
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <Fragment>
          {sectionSummaryFields.map((section) => {
            if (!!section?.isUniqueSection) {
              switch (section?.section) {
                case "Medicines Ordered":
                  return (
                    <div>
                      <MedsOrderedSummary
                        previousRoute="/epharmacy/summary"
                        medicines={medicines}
                        route="/epharmacy/order"
                      />
                      <OrderSubtotalSummary
                        addressType={deliveryAddress?.addressType}
                        province={deliveryAddress?.province?.value}
                        medicines={medicines}
                        coupon={coupon}
                      />
                    </div>
                  )
                case "Documents Uploaded":
                  return (
                    <UploadDocumentsSummary
                      files={uploadedDocuments}
                      route="/epharmacy/upload"
                      previousRoute="/epharmacy/summary"
                    />
                  )
                case "Payment Method":
                  return (
                    <Section
                      title="Payment Method"
                      addOns={{
                        right: (
                          <EditDetailsButton
                            previousRoute="/epharmacy/summary"
                            route="/epharmacy/payment-details"
                          />
                        ),
                      }}
                    >
                      <PaymentDetailsSummary
                        paymentOption={paymentOption}
                        changeFor={changeFor}
                      />
                    </Section>
                  )
                case "Consent and Authorization":
                  return (
                    <ConsentAndAuthorization
                      handleOnSubmit={handleOnSubmit}
                      flow="epharmacy"
                    >
                      <Message color="warning">
                        Order is not final until verified by our pharmacist via
                        email.
                      </Message>
                      <ActionButtons
                        submit={{
                          label: "Submit",
                          loading: loading,
                          disabled: shouldBeDisabled(
                            epharmacy,
                            medicines,
                            documents
                          ),
                        }}
                        back={{ label: "Back", link: pageContext?.backPath }}
                      />
                    </ConsentAndAuthorization>
                  )
              }
            } else {
              return (
                <Section
                  title={section?.section}
                  id={section?.sectionId || ""}
                  addOns={{
                    right: (
                      <EditDetailsButton
                        previousRoute="/epharmacy/summary"
                        route={section.link}
                      />
                    ),
                  }}
                >
                  {!hasIncompleteValues({
                    data: epharmacy,
                    fields: section.fields,
                  }) ? (
                    <div className="table-container">
                      <table class="table is-fullwidth is-size-5">
                        <tbody>
                          {section.fields
                            .sort((firstField, secondField) => {
                              return (
                                firstField.summary_order -
                                secondField.summary_order
                              )
                            })
                            .map((field) => {
                              let finalValue = null
                              switch (field.type) {
                                case "date":
                                  finalValue = get(epharmacy, field.name)
                                    ? `${
                                        get(epharmacy, field.name)?.month?.label
                                      } ${
                                        get(epharmacy, field.name)?.date?.label
                                          ? `${
                                              get(epharmacy, field.name)?.date
                                                ?.label
                                            }, `
                                          : ""
                                      }${
                                        get(epharmacy, field.name)?.year?.value
                                          ? `${
                                              get(epharmacy, field.name)?.year
                                                ?.value
                                            }`
                                          : ""
                                      }`
                                    : ""
                                  break
                                case "address":
                                  finalValue = get(epharmacy, field.name)
                                    ? `${
                                        get(epharmacy, field.name)?.houseNumber
                                      } ${
                                        get(epharmacy, field.name)
                                          ?.streetAddress
                                      }, ${
                                        get(epharmacy, field.name)?.barangay
                                          ?.label
                                      }, ${
                                        get(epharmacy, field.name)?.city?.label
                                      }, ${
                                        get(epharmacy, field.name)?.province
                                          ?.label
                                      }, (${
                                        get(epharmacy, field.name)?.notes ||
                                        "No notes"
                                      })`
                                    : ""
                                  break
                                case "select":
                                  finalValue = get(epharmacy, field.name)
                                    ? get(epharmacy, field.name)?.label
                                    : ""
                                  break
                                default:
                                  finalValue = "test"
                                  finalValue = get(epharmacy, field.name)
                                  break
                              }
                              if (!!finalValue)
                                return (
                                  <tr>
                                    <td
                                      className={classNames(
                                        "has-text-weight-bold"
                                      )}
                                    >
                                      {field.summaryLabel}
                                    </td>
                                    <td
                                      className={classNames(
                                        styles["summary__tableData"]
                                      )}
                                    >
                                      {finalValue}
                                    </td>
                                  </tr>
                                )
                              return null
                            })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <Message color="warning">
                      Some required information is missing or incomplete. Please
                      double check if you have filled out the required fields.
                    </Message>
                  )}
                </Section>
              )
            }
          })}
        </Fragment>
      </Container>
    </Layout>
  )
}

export default Summary
