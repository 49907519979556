import React from "react"
import { Link } from "gatsby"

import Section from "../Elements/Section"
import MedicineInfoRow from "./MedsOrderedSummary/MedicineInfoRow"
import EditDetailsButton from "./EditDetailsButton"
import EmptyDetailsWarningMessage from "./EmptyDetailsWarningMessage"

const MedsOrderedSummary = ({ previousRoute, medicines, route }) => (
  <Section
    title={`Medicines Ordered (${medicines.length})`}
    addOns={{
      right: <EditDetailsButton previousRoute={previousRoute} route={route} />,
    }}
    className="mb-0"
  >
    {medicines.length < 1 ? (
      <EmptyDetailsWarningMessage
        description={
          <div className="has-text-centered">
            You have not added any medicines.
            <br />
            <Link to="/epharmacy/order">Add Medicines</Link>
          </div>
        }
      />
    ) : (
      <div className="px-1">
        {medicines.map((item) => (
          <MedicineInfoRow medicine={item} />
        ))}
      </div>
    )}
  </Section>
)

export default MedsOrderedSummary
