import React from "react"
import classNames from "classnames"

import styles from "./utils/summary.module.scss"

const EmptyDetailsWarningMessage = ({ description }) => (
  <div className={classNames("is-flex px-1", styles["message"])}>
    <div className="notification is-warning p-1 mt-1 mb-1">
      <div className="columns is-vcentered p-0 m-0">
        <div className="column p-0 pr-1">{description}</div>
      </div>
    </div>
  </div>
)

export default EmptyDetailsWarningMessage
